.perf-employe-subtitle{
    font-size: small;
    color: grey;
}

.perf-dev{
    font-size: small;
    max-width: 100%;
}

.perf-edit{
    max-width: 100%;
}

.perf-reviews-container{
    max-width: 100%;
}