#root {
    height: 100%;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f0f5f5; /* Vous pouvez modifier cette couleur de fond */
    font-family: Arial, sans-serif; /* Ajoutez votre police préférée */
}

.input-container {
    margin-bottom: 10px;
}

.input-field {
    width: 80%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-bottom: 5px;
}

.error-message {
    color: red;
    margin-bottom: 10px;
}

.login-button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
}

.login-button:hover {
    background-color: #0056b3;
}


/* Ajoutez ceci à votre fichier CSS (e.g., Login.css) */
.error-message {
    color: red;
    font-weight: bold;
    display: flex;
    align-items: center;
    margin-top: 10px;
    animation: shake 0.5s;
}

@keyframes shake {
    0% { transform: translateX(0); }
    25% { transform: translateX(-5px); }
    50% { transform: translateX(5px); }
    75% { transform: translateX(-5px); }
    100% { transform: translateX(0); }
}

.error-icon {
    margin-right: 10px;
}


.error-message {
    display: flex;
    align-items: center;
    color: #e74c3c;
    margin-top: 10px;
}

.error-message .fa-exclamation-circle {
    margin-right: 5px;
}

.shake {
    animation: shake 0.5s;
    animation-iteration-count: 1;
}

@keyframes shake {
    0% { transform: translateX(0); }
    25% { transform: translateX(-5px); }
    50% { transform: translateX(5px); }
    75% { transform: translateX(-5px); }
    100% { transform: translateX(0); }
}

.password-strength {
    margin-top: 10px;
}

.strength-bar {
    height: 8px;
    background: #ddd;
    border-radius: 4px;
    overflow: hidden;
}

.strength-bar > div {
    height: 100%;
}
