
.header-icon {
    width: 30px;
    height: 30px;
    border-radius: 300px;
  }
  

.section-icon{
     display: flex;
     flex-direction: column;
     align-items: center;
     gap: 5px;
     
  }
.section-icon-text{
    font-size: small;
}
.section-header{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;

 }


 .agent-chat-bot-container {
   
    background-color: #f5f5f5;

    width: 450px;
    height: 250px;
    border-radius: 20px;


    overflow: hidden;
  }
  
  .agent-chat-history {
    height: 70%;
    padding: 10px;
    overflow-y: auto;
    background-color: #fff;
    border-bottom: 1px solid #ddd;
  }
  
  .agent-chat-input {
    height: 20%;
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: #f9f9f9;
  }
  
  .agent-chat-input input {
    flex: 1;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 5px 10px;
    font-size: 14px;
    outline: none;
  }
  
  .agent-chat-input button {
    margin-left: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 5px 15px;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .agent-chat-input button:hover {
    background-color: #0056b3;
  }
  
  .chat-user {
    text-align: right;
    margin: 5px 0;
    padding: 5px 10px;
    background-color: #e3f2fd;
    border-radius: 10px;
    display: inline-block;
  }
  
  .chat-bot {
    text-align: left;
    margin: 5px 0;
    padding: 5px 10px;
    background-color: #f1f1f1;
    border-radius: 10px;
    display: inline-block;
  }
  
  .typing-animation span {
    display: inline-block;
    width: 8px;
    height: 8px;
    margin: 0 2px;
    background-color: #bbb;
    border-radius: 50%;
    animation: typing 1.5s infinite ease-in-out;
  }
  
  .typing-animation span:nth-child(1) {
    animation-delay: 0s;
  }
  
  .typing-animation span:nth-child(2) {
    animation-delay: 0.2s;
  }
  
  .typing-animation span:nth-child(3) {
    animation-delay: 0.4s;
  }
  
  @keyframes typing {
    0%, 80%, 100% {
      transform: scale(0);
    }
    40% {
      transform: scale(1);
    }
  }
  