/* Conteneur principal pour la carte avec la gestion du positionnement */
.hover-info-container {
    position: relative;
    display: inline-block;
}

/* Carte flottante avec effet d'apparition et zoom */
.fade-in {
    position: absolute;
    top: 100%; /* Afficher juste en dessous de l'enfant */
    left: 50%;
    transform: translateX(-50%) scale(0.95); /* Ajout du scale initial */
    margin-top: 10px;
    padding: 8px;
    background-color: #003a45;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    z-index: 10;
    display: flex;
    align-items: center;
    opacity: 0;
    transition: opacity 0.4s ease 0.3s, transform 0.4s ease 0.3s; /* Délai d'apparition */
    width: fit-content;
}

/* Ajouter une flèche pointant vers le children */
.fade-in::before {
    content: '';
    position: absolute;
    top: -10px; /* Positionner au-dessus de la boîte */
    left: 50%;
    transform: translateX(-50%);
    border-width: 0 10px 10px 10px; /* Triangle inversé */
    border-style: solid;
    border-color: transparent transparent #003a45 transparent; /* Flèche blanche */
    z-index: 9;
}

/* Afficher la carte flottante avec effet de zoom et opacité */
.fade-in.show {
    opacity: 1;
    transform: translateX(-50%) scale(1); /* Zoom à 100% */
    transition-delay: 0.4s; /* Délai pour l'apparition */
}

/* Style pour l'icône dans la carte */
.hover-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    font-size: 18px;
}

/* Texte explicatif */
.hover-text {
    color: #fff;
    display: block;
    font-size: 12px;
    white-space: normal; /* Permet le retour à la ligne */
    word-wrap: break-word;
    min-width: 150px; /* Forcer la coupure des mots trop longs */
    max-width: 200px; /* Limiter la largeur du texte à une taille gérable */
}
