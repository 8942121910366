.sidebar {
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Changé pour aligner les éléments en haut et en bas */
    gap: 20px; /* Espace entre les éléments */
    color: black;
    position: sticky;
    top: 0;
    z-index: 1000;
    height: 100vh; /* Changé pour prendre toute la hauteur de l'écran */
    width: 300px;
    padding: 20px;
    box-sizing: border-box;

    border-right: 1px solid #00000010;
}


.logo  {
    max-width: 100px;
}

.logo_img {
    max-width: 30px;
}

.sidebar-top {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}
.sidebar-bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}