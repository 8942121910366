.container-home {
    display: flex;
    grid-template-columns: 0.5fr 2fr; /* Ceci définit le conteneur pour avoir deux colonnes de taille égale */
    gap: 16px; /* Optionnel : Ajoute de l'espace entre les divs */
    min-height: 100%; /* Permet au body d'occuper toute la hauteur de l'écran */
    width: 100%; /* Permet au body d'occuper toute la largeur de l'écran */
    margin: 0; /* Enlève la marge par défaut */
    padding: 0; /* Enlève le padding par défaut */
    box-sizing: border-box;
    

}

html, body, #root {
    min-height: 100%; /* Permet au body d'occuper toute la hauteur de l'écran */
    width: 100%; /* Permet au body d'occuper toute la largeur de l'écran */
    margin: 0; /* Enlève la marge par défaut */
    padding: 0; /* Enlève le padding par défaut */
    box-sizing: border-box; /* Permet de s'assurer que padding et border sont inclus dans la largeur et hauteur totale */
    background-color: #F0F5F5
}


.content {
    padding: 20px;
    display: grid;
    grid-gap: 25px; /* espace entre les cartes */
    grid-template-columns: repeat(2, 1fr);
    align-content: center;
    height: auto;
  }

.container-right {
    padding: 20px;
    grid-gap: 20px;
    display: flex;
    flex-direction: column;
    grid-template-columns: 1fr; /* une seule colonne */
    align-content: start;
    position: relative; /* ou 'sticky' selon le contexte */
    top: 0px; /* Hauteur de l'en-tête */
    width: inherit;
    overflow-x: auto;
    gap: 20px;
}


.titre-ligne {
    display: flex;
    gap: 20px;
    align-items: center;
  }

  .icon-plus {
    /* Styles normaux de l'icône */
    transition: transform 0.3s cubic-bezier(0.68, -0.55, 0.27, 1.55); /* Ajoute une transition pour une animation fluide */
  
    /* Définit l'angle de rotation initial à 0 degré */
    transform: rotate(0deg);
    cursor: pointer;
  }
  
  .icon-plus:hover {
    /* Styles au survol */
    transform: rotate(180deg); /* Fait tourner l'icône de 50 degrés au survol */
  }

.clickable {
    cursor: pointer;
  }

.clickable:hover {
  background-color: rgb(249, 249, 249);
  }

.tab-menu{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  }

.tab-menu-btn{
  padding: 10px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 18px;
  color: #00000060;

  }

.tab-menu-btn:hover{
  color: #000000;
  font-weight: bold;
    }

.tab-menu-btn-active{
      color: #000000;
      font-weight: bold;
      border-bottom: 1px solid #00667A;
        }


.team-container{
  display: flex;
  flex-direction: column;
  gap: 5px;

    }


.team-card{
  display: flex;
  height: 200px;
  box-sizing: border-box;
  flex-direction: column;
  border-radius: 10px;
  padding: 20px;
  background-color: #fff;
  width: 265px;
  box-sizing: border-box;
  gap: 10px;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

}

.team-line{
      display: flex;
      flex-direction: row;
      justify-content: space-between;

        }

.team-line:hover{
  background-color: #F0F5F5;
  cursor: pointer;
            }


.team-kpi{
    font-weight: 900;

      }