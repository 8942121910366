




.card-full {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding: 20px;
  overflow: hidden;
  background-color: #fff;
  width: 100%;
  box-sizing: border-box;
  gap: 10px;
}

.card-full.ia {
  background-image: linear-gradient(to right, #EDF4F5, #EBA49B50, #EDF4F5);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  transition: background-image 0.5s ease-in-out;
  cursor: pointer;
}
@keyframes pulse {
  0% { background-color: #EDF4F5; }
  50% { background-color: #EBA49B; }
  100% { background-color: #EDF4F5; }
}


.card-full.half-height {
  height: 200px;
}


.card-full.with-border {
  border: 2px solid #00667A; /* Ajoute une bordure solide de 2px noire */
  background-color: #e8f6f8;
}

.card-half-full {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding: 20px;

  background-color: #fff;
  width: 48.5%;
  box-sizing: border-box;

  gap: 10px; /* Espacement entre les éléments enfants */
  justify-content: flex-start; /* Alignement vertical en haut */
}


.card-half-full-info-box {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding: 20px;

  background-color: #fae5c880;
  border: 2px solid #ff783050;

  width: 48.5%;
  box-sizing: border-box;

  gap: 10px; /* Espacement entre les éléments enfants */
  justify-content: flex-start; /* Alignement vertical en haut */
}

.card-half-full.secondary {
  width: fit-content;

}



.cards-container {
  display: flex;
  flex-wrap: wrap; /* Permet aux éléments de passer à la ligne */
  grid-gap: 10px;
  align-items: flex-start; /* pour aligner les cartes verticalement */
  
  max-width: 100%;
}

.cards-container.haut {

  align-items: flex-start; /* pour aligner les cartes verticalement */

}

.cards-container.nowrap {

  flex-wrap: nowrap; /* Permet aux éléments de passer à la ligne */

}


/* Vous pouvez ajouter des styles supplémentaires ici pour personnaliser davantage le look de vos cartes. */


.kpi-card {
    align-items: center;
    background-color: #ffffff;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: 200px;
    justify-content: center;
    overflow: hidden;
    padding: 8px;
    position: relative;
    width: 200px;
    box-sizing: border-box;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }
  
  .kpi-card-kpi{
    gap: 10px;
    display: flex;
    flex-direction: line;
    color: #000000;
    font-size: 25px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    width: fit-content;
  }

  .kpi-card-subtext {
    color: #00667A;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    text-align: center;
    line-height: normal;
    position: relative;
    width: fit-content;
  }



  
  .kpi-card-kpi{
    color: #000;
    font-size: 25px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    width: fit-content;
  }

  

  .kpi-card-subtext {
    color: #00667A;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    width: fit-content;
  }




  .card-icons {

    display: flex;
    padding: 4px;
    justify-content: flex-end;
    box-sizing: border-box;
  }

  .card-icons-icon {
    color: #a3a3a3;
    cursor: pointer;
  }

  .card-icons-icon:hover {
    color: #00667A;
  }
.active {
    color: #00667A;
  }



.card-icon-header {
  display: flex;
  gap: 10px;
  justify-content: flex-end;

}

.line-display {
  display: flex;
  align-items: start;
  gap: 10px;
}

.line-margin-10 {
  margin-top: 10px;
}

.values {
  min-width: 45%;
  flex: 1;
  padding: 10px;
  display: flex;
  flex-direction: line; /* Added line */

  align-items: center; /* Updated to center align items */
  justify-content: center; /* Added to center align items horizontally */
  gap: 10px;
}



.positive {
  color: #06ad1f;
  font-weight: bold;
}

.negative{
  color: #a71111;
  font-weight: bold;
}

.fit-width {
  width: 100%;
}

.clickable {
  cursor: pointer;
}

.clickable:hover {
background-color: rgb(249, 249, 249);
}


.card-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  border-radius: 10px;
  background-color: #fff;
  width: 550px;
}

/* Nouveau design below */


.card-home{
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding: 20px;
  overflow: hidden;
  background-color: #fff;
  width: 265px;
  height: 200px;
  gap: 10px;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.card-home:hover{
  background-color: #f8f5f5;
}

.card-home-kpi{
  font-size: 32px;
  font-weight: bold;
}

.card-home-subtitles{
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.card-home-titre{
  font-size: 14px;
  color: #00000050;
}

.card-home-time{
  font-size: 10px;
  color: #00000050;
}


.card-home-chart{
  align-items: end;
  align-self: flex-end;
  width: 50%;
}

.card-insight {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding: 20px;
  overflow: hidden;
  background-image: linear-gradient(to right, #EDF4F5, #EBA49B50, #EDF4F5);
  width: 400px;
  height: 250px;
  box-sizing: border-box;
  gap: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  transition: background-image 0.5s ease-in-out;
  cursor: pointer;
}

.card-insight-titre{ 
  font-size: 16px;
  font-weight: bold;
  color: #000000;
}

.card-insight-subtitre{ 
  font-size: 14px;
  color: #000000;
}

.card-insight-icon { 
  color: #EBA49B;
  transition: transform 0.3s ease; /* Ajoute une transition douce */
}

.card-insight:hover .card-insight-icon{
  transform: translateX(50px); /* Déplace l'icône de 50px sur la droite */
}


.card-insight-separator{
  width: 100%;
  height: 1px;
  background-color: #00000010;
}

/* Card Survey  */

.container-survey-kpis{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 10px;
}
.container-survey-kpi{
  display: flex;
  flex-direction: column;
  gap: 5px;
  max-width: 140px;
  justify-content: space-between;
  align-items: center;
}

.card-survey-kpi{
  font-size: 30px;
  font-weight: bold;
}

.card-survey-kpi-text{
  font-size: 14px;
  color: #00000080;
  text-align: center;
}

.card-report {
  display: flex;
  flex-direction: row;
  border-radius: 10px;
  padding: 20px;
  overflow: hidden;
  background-color: #fff;
  box-sizing: border-box;
  gap: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  width: fit-content;
}

.card-report-icon {
  height: 50px;
  color :#EBA49B50;
}

.card-report:hover {
  box-shadow:0px 0px 10px #EBA49B90;
}

.card-report-titre {
  color: #000000;
  font-size: 14px;
  font-weight: bold
}

.margin{
  margin-bottom: 40px;
}


.status-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  color: rgb(0, 0, 0);
  padding: 10px;
  border-radius: 10px;
  width: 100px;


  position: relative;
}

.status-icon {
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 50%;
  width: 40px;
  height: 40px;
}

.status-open .status-icon {
  background-color: rgba(255, 0, 0, 0.3); /* Rouge pour Open */
}

.status-closed .status-icon {
  background-color: rgba(0, 128, 0, 0.3); /* Vert pour Closed */
}

.status-in-progress .status-icon {
  background-color: rgba(255, 166, 0, 0.3); /* Orange pour In Progress */
}

.status-pending .status-icon {
  background-color: rgba(0, 0, 255, 0.3); /* Bleu pour Pending */
}

.status-recrutement .status-icon {
  background-color: rgba(191, 255, 30, 0.3); /* Vert clair pour Recrutement */
}

.status-offre .status-icon {
  background-color: rgba(255, 166, 0, 0.3); /* Orange pour Offre */
}

.status-nego .status-icon {
  background-color: rgba(0, 0, 255, 0.3); /* Bleu pour Négociation */
}

.status-effectif .status-icon {
  background-color: rgba(207, 207, 207, 0.3); /* Gris pour Effectif Restant */
}
.status-icon img {
  width: 20px;
  height: 20px;
}

.status-label {
  margin-left: 10px;
  font-size: 12px;
  font-weight: bold;

}

.status-count {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
}


.status-left {
  height: 100%;
  gap: 10px;
  display: flex;
  align-items: flex-start;
  
  justify-content: space-between;
  flex-direction: column;
}


.status-select{
  width: fit-content;
  padding: 5px;
  border-radius: 10px;
}




.card-modale {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding: 20px;
  overflow: hidden;
  background-color: #fff;
  width: 50%;
  box-sizing: border-box;
  gap: 10px;
  align-items: flex-start;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}
