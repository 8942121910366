.button-sidebar {
    display: flex;
    align-items: center;
    padding: 10px ;
    border: none;
    border-radius: 10px;
    background-color: #F0F5F5;
    font-size: 14px;
    cursor: pointer;
    gap: 10px; 
    transition: background-color 0.3s;
    width: 100%;
}

.button-sidebar:hover {
    background-color: #e4ebeb99;
}

.button-sidebar.active {
    background-color: #e4ebeb; /* Choisissez une couleur qui indique l'état actif */
    /* Vous pouvez également changer d'autres propriétés si nécessaire, comme la couleur du texte, la bordure, etc. */

}


.button-sidebar.active .fa-icon, /* Classe pour l'icône */
.button-sidebar.active .button-text { /* Classe pour le texte */
    color: #00667A; /* Couleur pour l'icône et le texte en état actif */
}

.button-sidebar.active svg {
    color: #00667A; /* Couleur de l'icône FontAwesome pour l'état actif */
}