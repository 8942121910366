.increase-selector {
    display: flex;
    align-items: center;
    justify-content: center;
}

.increase-selector button {
    background-color: #00667A;
    color: white;
    border: none;
    padding: 5px 10px;
    margin: 0 5px;
    cursor: pointer;
    border-radius: 3px;
}

.increase-selector span {
    width: 30px;
    text-align: center;
}


.table-container-2{
  width: 100%;
  overflow-x: auto;
  position: relative;
}

.table-custom {
    width: 100%;
    border-collapse: collapse;

    overflow-x: auto;
  }
  
  .table-custom th {

    background-color: #87878710;
  }

  .table-custom th, .table-custom td {
    border: 1px solid #cccccc50;
    padding: 8px;
    text-align: center;
    min-width: 100px; /* Minimum width for columns */

  }
  
  .table-custom th:first-child, .table-custom td:first-child {
    border-left: none;
  }
  
  .table-custom th:last-child, .table-custom td:last-child {
    border-right: none;
  }
  
  .table-custom th {
    border-top: none;
    position: relative; /* Necessary for resizer */
    font-size: small;
  }
  
  .table-custom td {
    border-bottom: none;
  }
  
  .table-custom tr.clickable:hover{
    background-color: rgb(247, 247, 247)te;
  }

  .resizer {
    position: absolute;
    top: 0;
    right: 0;
    width: 5px;
    cursor: col-resize;
    user-select: none;
  }
  .checkbox {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    
}



.table-container{
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
  }



.filter-tag{
  display: flex;
  flex-direction: row;
  font-size: x-small;
  align-items: center;
  gap: 5px;
  border-radius: 10px;
  background-color: #00667A10;
  padding: 5px;
  width: fit-content;

  border: 1px solid #00667A50;
}


.filter-tag-btn{
  border: 1px solid #00667A00;
  border-radius: 10px;
  background-color: #ffffff10;
  padding: 5px;
  width: fit-content;
  font-size: x-small;
}

.filter-tag-btn:hover{
  border: 1px solid #00667A10;
  cursor: pointer;
}


.fixed-col {
  position: sticky;
  left: 0;
  background-color: #fff; /* Assurez-vous que la colonne fixe reste lisible avec un fond */
  z-index: 1; /* Pour s'assurer que la colonne reste au-dessus lors du défilement */
}

.fixed-header {
  position: sticky;
  top: 0;
  z-index: 2; /* Plus élevé pour que l'en-tête reste au-dessus des autres cellules */
  background-color: #f4f4f4; /* Fond pour différencier l'en-tête */
}

.table-custom th.fixed-col {
  background-color: #f4f4f4; /* Fond pour l'en-tête de la première colonne */
}