.logout-button {
    background-color: #00667A; /* Couleur de fond rouge */
    color: white; /* Texte blanc */
    border: none; /* Pas de bordure */
    padding: 10px ; /* Padding */
    text-align: center; /* Alignement du texte */
    text-decoration: none; /* Pas de décoration de texte */
    display: inline-block; /* Display inline-block */
    font-size: 16px; /* Taille de la police */
    cursor: pointer; /* Curseur pointeur */
    border-radius: 10px; /* Bords arrondis */
    width: 100%; /* Largeur de 100% */
}



.logout-button:hover {
    background-color: #18798d; /* Couleur de fond rouge */

}