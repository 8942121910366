
/* Card.css */

.card-kpi-staff {
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    min-width: 225px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.card-kpi-staff h5 {
    font-size: 14px;
    color: #7d7d7d;
}

.card-kpi-staff p {
    font-size: 24px;
    font-weight: bold;
    color: #333;
}

.div-positions-table {
    width: 100%;

    background-color: #fff;

    padding: 10px;
    border-radius: 10px;
}

.positions-table {
    width: 100%;
    border-collapse: collapse;
    background-color: #fff;

    padding: 15px;
    border-radius: 10px;
}

.positions-table th, .positions-table td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

/* Applique un fond différent au survol, sauf si la ligne est sélectionnée */
.positions-table tbody tr:hover:not(.selected) {
    background-color: #f0f4ff;
}

.positions-table tbody tr.selected {
    background-color: #d0ebff; /* Couleur de fond pour une ligne sélectionnée */
    color: #333; /* Couleur de texte personnalisée, si nécessaire */
}



.positions-table th {

    font-weight: bold;
}

.positions-table td {
    font-size: 14px;
}


/* Couleurs pour les statuts */
.status {
/* Assure que la taille de l'élément s'ajuste au texte */
    display: inline-block;    
    padding: 4px 6px;
    border-radius: 10px;
}


.status.NeedsReview { background-color: #f57c0030; }
.status.Requested { background-color: #ffb30030; }
.status.AwaitingApproval { background-color: #e5737330; }
.status.InProgress { background-color: #64b5f630; }
.status.Approved { background-color: #81c78430; }

/* Style pour le YoY */
.card-kpi-staff span {
    font-size: 12px;
    padding: 10px;
    border-radius: 20px;
    color: #f44336;

    font-weight: normal;
    margin-left: 5px;
}


/* Barre d'outils */
.toolbar {
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-bottom: 10px;
}

.toolbar span {
    margin-right: 15px;
    font-size: 14px;
}

.toolbar button {
    padding: 6px 12px;
    margin-right: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: #007bff;
    color: white;
}

.toolbar button:last-child {
    background-color: #dc3545;
}

/* Style pour les lignes déclinées */
.positions-table tbody tr.declined {
    opacity: 0.3;
}


.nav-btn-new{
    font-size: 12px;
}

.notification-container {
    width: 100%;
    margin-bottom: 10px;
}

.notification {
    display: inline-flex;
    background-color: #fff;
    padding: 10px 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    animation: slideDown 1s forwards;
}

.notification.savings {
    border-color: green;
    color: #fff;
    font-weight: 500;
    background-color: green;
}

.notification.impact {
    border-color: red;
    color: #fff;
    font-weight: 500;
    background-color: red;
}

@keyframes slideDown {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}


.display-line-flex{
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: flex-start;
    flex-wrap: wrap;
}

.success-message{
    width: 100%;
    padding: 20px;
    background-color: #93D07740;
    border-radius: 10px;
    box-sizing: border-box;
}

/* Modal.css */

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}


.modal-container {
    max-width: 500px;
    width: 90%;
    margin: auto;
    padding: 20px;
    background: white;
    border-radius: 12px;
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
    animation: fadeIn 0.4s ease-out;
}

.modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
}


.hc-form {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;

}

.hc-form label {
    color: #4a4a4a;
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 5px;
}

.hc-form-line {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.hc-form input,
.hc-form select {
    font-size: 15px;
    border-radius: 8px;
    border: 1px solid #ddd;
    padding: 10px;
    transition: box-shadow 0.3s ease, border-color 0.3s ease;
    background: #fafafa;
}
.hc-form input:focus,
.hc-form select:focus {
    outline: none;
    border-color: #00667A;
    box-shadow: 0 0 5px rgba(0, 102, 122, 0.5);
    background: #ffffff;
}



.hc-display-line{
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    flex-wrap: wrap;
}

.hc-pagination-control{
    font-size: 10px;
   
}


@keyframes fadeIn {
    from {
        opacity: 0;
        transform: scale(0.9);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}

.hc-form textarea {
    font-size: 15px; /* Taille du texte */
    border-radius: 8px; /* Coins arrondis */
    border: 1px solid #ddd; /* Bordure */
    padding: 10px; /* Espacement interne */
    background: #fafafa; /* Couleur de fond */
    resize: vertical; /* Permet de redimensionner verticalement */
    min-height: 100px; /* Hauteur minimum pour rendre l'écriture confortable */
    transition: box-shadow 0.3s ease, border-color 0.3s ease; /* Animation pour le focus */
}

.hc-form textarea:focus {
    outline: none; /* Retire la bordure par défaut au focus */
    border-color: #00667A; /* Couleur de bordure au focus */
    box-shadow: 0 0 5px rgba(0, 102, 122, 0.5); /* Effet lumineux au focus */
    background: #ffffff; /* Couleur de fond au focus */
}

.hc-form textarea::placeholder {
    color: #aaa; /* Couleur du texte indicatif */
    font-style: italic; /* Style en italique pour différencier du contenu */
}


/* Styling for status cells */
.status-cell {

    display: inline-block;    
    padding: 4px 6px;
    border-radius: 10px;

}

/* Specific colors for each status */
.status-cell.planned {
    background-color: #f1c40f30; /* Yellow for planned */
}

.status-cell.approved {
    background-color: #2ecc7130; /* Green for approved */
}

.status-cell.cancelled {
    background-color: #e74c3c30; /* Red for cancelled */
}

.filters-container {
    margin-bottom: 20px;
}

.filters-dropdown {
    margin-top: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: #fff;
}




.reset-button {
    margin-top: 10px;
    padding: 5px 10px;
    background-color: #d36e0f;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
.apply-button {
    margin-top: 10px;
    padding: 5px 10px;
    background-color: #00667A;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}


.filters-container {
    margin: 10px 0;
}
.filter-actions {
    display: flex;
    flex-direction: row;
    gap: 10px;
}
.applied-filters {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    margin: 10px 0;
}

.filter-tag {
    background-color: #f0f0f0;
    padding: 5px 10px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 14px;
}

.filter-tag button {
    background: none;
    border: none;
    color: red;
    cursor: pointer;
    font-size: 16px;
    line-height: 1;
}


.hc-container-chart {
    width: 100%;
    height: 200px;
    background-color: #fff;
    border-radius: 10px;
    padding: 10px;
}




/* Animation */

.validation-feedback {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    gap: 10px;
    font-size: 18px;
    color: #28a745; /* Couleur verte pour valider */
    animation: fadeInScale 0.6s ease-out;
}

.check-icon {
    font-size: 36px;
    animation: bounce 1.5s infinite;
}

@keyframes fadeInScale {
    from {
        opacity: 0;
        transform: scale(0.8);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes bounce {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-5px);
    }
}
