.toggle-button {
    display: flex;
    width: 150px;
    border: 1px solid #ccc;
    border-radius: 20px;
    overflow: hidden;
    cursor: pointer;
  }
  
  .toggle-option {
    flex: 1;
    display: flex;
    flex-direction: row;
    text-align: center;
    padding: 5px;
    background-color: #f0f0f0;
    transition: background-color 0.3s, color 0.3s;
    gap: 5px;
    justify-content: center;
    align-items: center;
    font-size: 12px;
  }
  
  .toggle-option.active {
    background-color: #00667A;
    color: white;
  }
  