.line-container-10 {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    max-width: 100%;
}

.container-page-10 {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.line-padding-bottom-10 {
    padding-bottom: 10px;
}

.cards-container {
    display: flex;
    flex-wrap: wrap; /* Permet aux éléments de passer à la ligne */
    grid-gap: 10px;
    align-items: center; /* pour aligner les cartes verticalement */
    max-width: 100%;
  }




  .cards-container-reports {
    display: flex;

    grid-gap: 10px;
    align-items: left; /* pour aligner les cartes verticalement */
    max-width: 100%;
    justify-content: space-between;
  }

.btn-column-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
}

.container-center {
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    height: 100%;
    gap: 10px;
}

.padding-100 {
    padding: 100px;
}


.container-full {
    width: 100%;
    box-sizing: border-box;

  }


.display-line {
    display: flex;
    flex-direction: row;
    gap: 10px;

    flex-wrap: wrap;
    align-items: flex-start;
}
  
.display-line-center {
    display: flex;
    flex-direction: row;
    gap: 10px;

    flex-wrap: wrap;
    align-items: center;
}


.display-column {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.max-700 {
    max-width: 700px;
}

.max-height-400 {
    max-height: 400px;
}

.container-full-report{
    display: grid;
    grid-template-columns: 1fr 0.5fr; /* Deux colonnes de largeur égale */
    gap: 20px; /* Espacement entre les colonnes */
    max-width: 800px;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
  }
  
  .container-modale {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    align-items: center;
    margin-top: 100px;
}


.icon-toggle {
    background-color: #ffffff;
    display: flex;
    width: fit-content;

    border-radius: 30px;
    overflow: hidden;

}

.icon-text{
    transition:  width 0.5s ease-in-out;
    width: 0px;
    overflow: hidden;
    text-align: center;
}
.icon-text.active{
    width: 75px;
    color: #fff;
}



.icon {
    cursor: pointer;
    transition:  background-color 0.5s ease-in-out;
    color: #00667A;
    background-color: transparent;
    padding: 10px; /* Ajouter du padding à l'icône pour compenser */
    display: flex;
    gap: 5px;

}

.icon.active {
    background-color: #00667A;
    color: #fff;

}

.tab-content{
    display: flex;
    gap: 10px;
    width: 100%;

}

.tab-content > * {
    flex: 1 0 auto; /* Grandir pour remplir l'espace, ne pas rétrécir, base à la taille du contenu */
}


.container-perf {
    display: grid;
    grid-template-columns: 0.5fr 1fr; /* Ceci définit le conteneur pour avoir deux colonnes de taille égale */
    gap: 20px; /* Optionnel : Ajoute de l'espace entre les divs */
    width: 100%; /* Permet au body d'occuper toute la largeur de l'écran */
    box-sizing: border-box;
}

.container-perf-left-head {
    display: flex;
    flex-direction: column;
    gap: 10px; /* Optionnel : Ajoute de l'espace entre les divs */
    width: 100%; /* Permet au body d'occuper toute la largeur de l'écran */
    box-sizing: border-box;

    align-items: center;
    
}
