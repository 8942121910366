table {
    width: 100%; /* La table prend toute la largeur du conteneur */

}

th {
    text-align: left; /* Alignement du texte à gauche */
    font-size: 12px;
}


tr {
    text-align: left; /* Alignement du texte à gauche */
}

.icon {
    text-align: right; 
}

.icon-edit {
    color: black; 
    cursor: pointer;
}
.icon-edit:hover {
    color: #00667A; 

}

.center{
    text-align: center;
    width: 100%;
}

.header-graph{
    font-size: 12px;
    margin: 0px;
}

.list-home-right{
    font-size: 14px;
    text-align: center;
}
.list-home{
    font-size: 14px;

}

.input-list{
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #00667A;
}

.table-row {
    height: 50px; /* Hauteur fixe pour chaque rangée */
}

.table-column-main {
    width: 150px; /* Hauteur fixe pour chaque rangée */
}

.list-employes{
    max-height: 200px;
    overflow-y: auto;
}


td.icon-actions {
    width: fit-content; /* Pour que la colonne prenne seulement la place de son contenu */
}



.list-equipes{
    max-height: 200px;
    overflow-y: auto;
}

.no-padding-list {
    list-style-type: none; /* Supprime les puces ou les numéros */
    padding: 0; /* Supprime le padding par défaut */
    margin: 0; /* Supprime la marge par défaut (facultatif) */
}