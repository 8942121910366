/* Conteneur du tableau */
.tableau-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

/* Conteneur défilable pour le tableau */
.tableau-scrollable {
    overflow-x: auto;
    width: 100%;
}

/* Style général du tableau */
.tableau-general {
    width: 100%;
    border-collapse: collapse; /* Pour des bordures nettes entre les cellules */
    border: 1px solid #ddd; /* Bordure autour du tableau */
}

/* Style des en-têtes du tableau */
.tableau-general thead th {
    position: sticky;
    top: 0;
    background: white;
    z-index: 1;
    height: 100%;
    min-width: 100px;
    overflow: hidden;
    text-align: center;
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    min-height: 40px; /* Hauteur minimale pour les en-têtes */
}

/* Style pour la première cellule de chaque ligne */
.tableau-general td:first-child, .tableau-general th:first-child {
    position: sticky;
    width: 150px;
    left: 0;
    background: rgb(244, 244, 244);
    z-index: 1202;
    min-height: 40px; /* Hauteur minimale pour la première cellule */
}

/* Styles pour les cellules du tableau */

.tableau-general td, .tableau-general th {
    text-align: center;
    border-right: 1px solid #ddd; /* Bordure verticale */
    border-bottom: 1px solid #ddd; /* Bordure horizontale */
    padding: 10px; /* Ajustez le padding si nécessaire */
}

/* Pour éviter une double bordure à l'extrémité droite du tableau */
.tableau-general td:last-child, .tableau-general th:last-child {
    border-right: none;
}

/* Pour éviter une double bordure au bas du tableau */
.tableau-general tr:last-child td {
    border-bottom: none;
}


/* Style des en-têtes du tableau */
.tableau-general tr {
    height: 50px;
}

/* Tableau.css */
.status-open {
    
    background-color: rgba(255, 0, 0, 0.3);
}

.status-closed {
    background-color:rgba(0, 128, 0, 0.3) ;
}

.status-in-progress {
    background-color: rgba(255, 166, 0, 0.3);
}

.status-pending {
    background-color: #EBA49B80;
}


.page-nav{
    justify-content: flex-end;
    font-size: x-small;
    grid-gap: 10px;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    max-width: 100%;
    padding: 5px;
}
.page-nav.police{
    font-size: small;
}

.checkbox{
    font-size: small;
}

.selector{
    padding-bottom: 10px;
    flex-wrap: wrap;
}

.selected{
    font-weight: 900;
    color: #00667A;
}


/* Status card colors with gradient from red to green and 30% opacity */
.status-not-started {
    background-color: rgba(255, 0, 0, 0.3); /* Red */
}

.status-job-opened {
    background-color: rgba(255, 85, 0, 0.3); /* Orange-red */
}

.status-screening {
    background-color: rgba(255, 170, 0, 0.3); /* Orange */
}

.status-interviewing {
    background-color: rgba(255, 255, 0, 0.3); /* Yellow */
}

.status-interviewing-2 {
    background-color: rgba(170, 255, 0, 0.3); /* Yellow-green */
}

.status-offer-extended {
    background-color: rgba(85, 255, 0, 0.3); /* Light green */
}

.status-offer-accepted {
    background-color: rgba(0, 255, 0, 0.3); /* Green */
}

.status-arrived {
    background-color: rgba(0, 128, 0, 0.3); /* Dark green */
}


.filter-select{
    border-radius: 10px;
    padding: 5px;
}

/* Tableau.css */

.percentage-fill-0 { background: linear-gradient(to right, #ffebeb 0%, transparent 0%); }
.percentage-fill-10 { background: linear-gradient(to right, #020000 10%, transparent 10%); }
.percentage-fill-20 { background: linear-gradient(to right, #ffcccc 20%, transparent 20%); }
.percentage-fill-30 { background: linear-gradient(to right, #ff9999 30%, transparent 30%); }
.percentage-fill-40 { background: linear-gradient(to right, #ff6666 40%, transparent 40%); }
.percentage-fill-50 { background: linear-gradient(to right, #ff4c4c 50%, transparent 50%); }
.percentage-fill-60 { background: linear-gradient(to right, #ff3232 60%, transparent 60%); }
.percentage-fill-70 { background: linear-gradient(to right, #ff1919 70%, transparent 70%); }
.percentage-fill-80 { background: linear-gradient(to right, #ff0000 80%, transparent 80%); }
.percentage-fill-90 { background: linear-gradient(to right, #e60000 90%, transparent 90%); }
.percentage-fill-100 { background: linear-gradient(to right, #cc0000 100%, transparent 100%); }
