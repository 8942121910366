.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
  
}body, html {
min-height: 100%;
background-color: #F0F5F5;
}



.App-link {
  color: #61dafb;
}




.btn-primary{
  background-color: #00667a;
  padding: 10px;
  color: white;
  border-radius: 10px;
  min-width: 150px;
  border-style: none;
  font-size: large;
}



p {
  padding: 4px;
  margin: 0;
}

