





.btn-secondary {
    display: flex;
    align-items: center;
    transition: background-color 0.3s; /* Transition douce pour l'arrière-plan */
    cursor: pointer;
    padding: 8px;
    border-radius: 30px;
    display: inline-block;
    cursor: pointer;
    width: fit-content;
    border: none;
    background-color: #ffffff00;
  }
  
  .btn-secondary:hover {
    background-color: #e4ebeb99; /* Changez cette couleur selon vos préférences */
  }

  
 .full-width {
    width: 100% /* Changez cette couleur selon vos préférences */
  }




  .btn-danger {
    display: flex;
    align-items: center;
    transition: background-color 0.3s; /* Transition douce pour l'arrière-plan */
    cursor: pointer;
    padding: 8px;
    border-radius: 30px;
    display: inline-block;
    cursor: pointer;
    width: fit-content;
    border: none;
    background-color: #ffffff00;
  }
  
  .btn-danger:hover {
    background-color: #ed999999; /* Changez cette couleur selon vos préférences */
  }


/* Styles communs aux boutons */
.btn-duplicate, .btn-delete {
  padding: 5px 10px;
  margin: 0 5px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
}

/* Style pour le bouton Duplicate */
.btn-duplicate {
  background-color: #00667a; /* Vert */
  color: white;
  transition: background-color 0.3s ease;
}

.btn-duplicate:hover {
  background-color: #014654; /* Vert plus foncé au survol */
}

/* Style pour le bouton Delete */
.btn-delete {
  background-color: #f44336; /* Rouge */
  color: white;
  transition: background-color 0.3s ease;
}

.btn-delete:hover {
  background-color: #d32f2f; /* Rouge plus foncé au survol */
}


.button-container {
  display: flex;
  gap: 8px; /* Espacement entre les boutons */
}

.button-cfo {
  display: flex;
  align-items: center;
  gap: 6px; /* Espacement entre l'icône et le texte */
  padding: 8px 12px;
  background-color: #ffffff; /* Couleur de fond */
  border: 1px solid #e0e0e0; /* Bordure légère */
  border-radius: 8px; /* Coins arrondis */
  font-family: Arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #333; /* Couleur du texte */
  cursor: pointer;
  transition: background-color 0.2s ease, box-shadow 0.2s ease;
}

.button-cfo:hover {
  background-color: #ffffff; /* Légère variation au survol */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); /* Ombre douce */
}


