/* Wrapper pour positionner le chat */
.chat-bot-wrapper {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
}

/* Bouton d'ouverture */
.chat-bot-toggle-button {
    background-color: #00667A;
    color: white;
    border: none;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    font-size: 16px;
    cursor: pointer;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    overflow: hidden;
}

.chat-bot-toggle-button img.chat-icon {
    width: 70px;
    height: 70px;
    object-fit: contain;
}

.chat-icon-little {
    width: 30px;
    height: 30px;
    border-radius: 350px;
}

/* Conteneur de chat */
.chat-bot-container {
    width: 300px;
    height: 400px;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    position: fixed;
    bottom: 20px;
    right: 20px;
}

.chat-bot-container {
    transition: transform 0.4s ease, opacity 0.4s ease;
    opacity: 1;
    transform: translateY(0);
}

.chat-bot-container.hidden {
    opacity: 0;
    transform: translateY(20px);
    pointer-events: none; /* Empêche l'interaction pendant que c'est caché */
}

/* Animation de glissement */
.chat-user,
.chat-bot {
    animation: slideIn 0.3s ease-out;
}

@keyframes slideIn {
    0% {
        opacity: 0;
        transform: translateY(10px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
.chat-bot-toggle-button:hover {
    animation: pulse 1s infinite;
}

@keyframes pulse {
    0% {
        transform: scale(1);
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }
    50% {
        transform: scale(1.1);
        box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
    }
    100% {
        transform: scale(1);
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }
}


/* En-tête */
.chat-header {
    background-color: #00667A;
    color: white;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.chat-close-button {
    background: none;
    border: none;
    color: white;
    font-size: 18px;
    cursor: pointer;
}

/* Historique des messages */
.chat-history {
    flex: 1;
    padding: 10px;
    overflow-y: auto;
    background-color: #f9f9f9;
    display: flex;
    flex-direction: column; /* Stack messages vertically */
    gap: 5px; /* Add spacing between messages */
}

.chat-user {
    align-self: flex-start;
    background-color: #00667A;
    color: white;
    padding: 8px 12px;
    margin: 5px 0;
    border-radius: 15px;
    max-width: 80%;
}

.chat-bot {
    align-self: flex-end;
    background-color: #e9ecef;
    padding: 8px 12px;
    margin: 5px 0;
    border-radius: 15px;
    max-width: 80%;
}

/* Entrée utilisateur */
.chat-input {
    padding: 10px;
    border-top: 1px solid #ddd;
    display: flex;
    gap: 10px;
    background-color: white;
}

.chat-input input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.chat-input button {
    background-color: #00667A;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 15px;
    cursor: pointer;
}

/* Typing Animation */
.typing-animation {
    display: flex;
    align-items: center;
    gap: 4px;
    justify-content: center;
}

.typing-animation span {
    display: inline-block;
    width: 7px;
    height: 7px;
    background-color: #00667A; /* Couleur des points */
    border-radius: 50%;
    animation: mercuryTypingAnimation 1.8s infinite ease-in-out;
}

.typing-animation span:nth-child(1) {
    animation-delay: 200ms;
}

.typing-animation span:nth-child(2) {
    animation-delay: 300ms;
}

.typing-animation span:nth-child(3) {
    animation-delay: 400ms;
}

/* Animation pour le clignotement des points */
@keyframes mercuryTypingAnimation {
    0% {
        transform: translateY(0);
        background-color: #00667A; /* Couleur initiale */
    }
    28% {
        transform: translateY(-7px);
        background-color: #9ECAB9; /* Couleur d'animation */
    }
    44% {
        transform: translateY(0);
        background-color: #B5D9CB; /* Couleur finale */
    }
}
