.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 50px 10px 50px;

    position: sticky;
    top: 0;
    z-index: 1000;
    background-color: #F0F5F5;
  }
  
  .icon-button {
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .user-info {
    display: flex;
    align-items: center;
    transition: background-color 0.3s; /* Transition douce pour l'arrière-plan */
    cursor: pointer;
    padding: 8px;
    border-radius: 30px;
  }
  
  .user-info:hover {
    background-color: #e4ebeb99; /* Changez cette couleur selon vos préférences */
  }

  .user-info span {
    margin-left: 8px;
  }
  
.header-right{
  display: flex;
  flex-direction: row;
}
  
.header-left{
  display: flex;
  flex-direction: row;
  gap: 10px;
}


/* Header.css */
.dropdown select {
  background-color: transparent; /* Aucun fond */
  border: 1px solid #00667A; /* Bordure de couleur #000006 */
  color: #00667A; /* Couleur du texte pour améliorer la lisibilité */
  padding: 5px; /* Un peu de padding pour l'esthétique */
  border-radius: 10px; /* Bordures arrondies */
  outline: none; /* Enlever le focus outline pour un look plus propre */
  cursor: pointer; /* Changement du curseur pour indiquer la sélection */
  max-width: 150px;
  font-size: 14px;
  font-weight: 500;

}

.dropdown select :hover{
  border: 2px solid #00667A; /* Bordure de couleur #000006 */
}

/* Améliorer l'apparence des options */
.dropdown select option {
  background-color: transparent; /* Fond blanc pour les options */
  color: #00667A; /* Couleur du texte */
}