h1 {
    font-size: 36px;
    padding: 0;
    margin: 0;
}

h2 {
    font-size: 30px;
    padding: 0;
    margin: 0;
}

h3 {
    font-size: 24px;
    padding: 0;
    margin: 0;
}

h4 {
    font-size: 20px;
    padding: 0;
    margin: 0;
}

h5 {
    font-size: 16px;
    padding: 0;
    margin: 0;
}

p {
    font-size: 14px;
    padding: 0;
    margin: 0;
}
td {
    font-size: 14px;
    padding: 0;
    margin: 0;
}


.refresh-text {
    font-size: 12px;
    padding: 0;
    margin: 0;
}

