.graphique-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
  }
  
  .graphique-header-right {
    display: flex;
    align-items: center;
    gap: 10px; /* Utilisez gap pour créer de l'espace entre les éléments */
  }
  
.icon-edit {
    color: black; 
    cursor: pointer;
}
.icon-edit:hover {
    color: #00667A; 

}

.custom-select {
    padding: 4px;
    border-radius: 10px;
    border: 1px solid #00667A;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;

}

.custom-select:hover {
background-color: rgb(232, 232, 232)

}

.selects-btn {
    display: flex;
    gap: 10px;
    justify-content: start;
    margin-top: 10px;
    margin-bottom: 10px;
  }

.max-width-donut{
  max-width:375px;
}