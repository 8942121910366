.chart-container {
    width: 100%;
    height: 450px;
    box-sizing: border-box;
  }

  /* Styles pour les KPI individuels */
.chart-kpi-container h4 {
    font-size: 18px;
    font-weight: 500;
    color: #555;
    margin-bottom: 5px;
}

.chart-kpi-container h3 {
    margin-top: 5px;
    margin-bottom: 5px;
}

.chart-kpi-container p {
    font-size: 20px;
    font-weight: bold;
    color: #333;
    margin: 0;
}


.kpi-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 200px;
    margin: 20px;
}

.kpi-header {
    display: flex;
    gap: 5px;
    align-items: center;
    font-size: 14px;
    color: #333;
    margin-bottom: 10px;
}

.kpi-header .kpi-icon {
    margin-right: 5px;
    color: #007bff;
}

.kpi-data {
    display: flex;
    align-items: baseline;
    margin-bottom: 10px;
}

.kpi-value {
    font-size: 32px;
    font-weight: bold;
    color: #333;
    margin-right: 10px;
}

.kpi-change {
    display: flex;
    align-items: center;
    color: #28a745;
    font-weight: 600;
}

.kpi-arrow {
    color: #28a745;
    margin-right: 2px;
}

.kpi-subtext {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    color: #666;
}

.kpi-subtext span {
    margin-bottom: 2px;
}


.content-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.filters-container {
    width: 30%; /* Ajustez cette valeur pour la section filtres */
    padding-right: 20px;
}

.chart-lines {
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
}
.chart-lines span{
    font-size: small;
}

.chart-dates {
    border-radius: 10px;
    border: 1px solid #ddd; /* Bordure subtile */
    padding: 5px 5px;
    transition: background-color 0.3s;
    font-size: small;
    width: 95px;
}

.chart-dates:hover {
    background-color: #f5f9fa;
}


.filters-container  {
    font-size: x-small;

}

.container-data {

width: 70%;
 display : flex;
 flex-direction: column;
 gap: 10px;

}

.table-chart{

     overflow-x: auto;
    }

.table-chart table {
        width: 100%;
        border-collapse: collapse;
    }