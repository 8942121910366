.form-group {
    margin-bottom: 15px; /* Espace entre les champs */
}

.form-group input {
    width: 100%; /* Pleine largeur */
    padding: 8px 10px; /* Padding intérieur pour rendre le champ plus grand */
    border: 1px solid #ddd; /* Bordure subtile */
    border-radius: 4px; /* Bords arrondis */
    box-sizing: border-box; /* Box sizing pour gérer correctement la largeur */
}

.form-column {
    display: flex;
    flex-direction: column;
    gap: 10px; /* Espacement entre les champs */
   
}

.label-column {
    display: flex;
    flex-direction: column;
    gap: 5px; /* Espacement entre les champs */
   
}

.form-input {
    border-radius: 10px;
    min-width: 100px;
    border: 1px solid #ddd; /* Bordure subtile */
    padding: 8px 10px;
    transition: background-color 0.3s;
}

.form-input:hover {
    background-color: #f5f9fa;
}


button[type="submit"] {
    background-color: #00667A; /* Couleur de fond rouge */
    color: white; /* Texte blanc */
    border: none; /* Pas de bordure */
    padding: 10px 20px ; /* Padding */
    text-align: center; /* Alignement du texte */
    text-decoration: none; /* Pas de décoration de texte */
    display: inline-block; /* Display inline-block */
    font-size: 14px; /* Taille de la police */
    cursor: pointer; /* Curseur pointeur */
    border-radius: 10px; /* Bords arrondis */
    width: fit-content;
}

button[type="submit"]:hover {
    background-color: #07788e; /* Assombrir le bouton au survol */
}


.logout-button {
    background-color: #00667A; /* Couleur de fond rouge */
    color: white; /* Texte blanc */
    border: none; /* Pas de bordure */
    padding: 10px ; /* Padding */
    text-align: center; /* Alignement du texte */
    text-decoration: none; /* Pas de décoration de texte */
    display: inline-block; /* Display inline-block */
    font-size: 16px; /* Taille de la police */
    cursor: pointer; /* Curseur pointeur */
    border-radius: 10px; /* Bords arrondis */
}


.employe-form-container {
    overflow: hidden;
    transition: max-height 0.5s ease-out, opacity 0.5s ease;
    max-height: 0;
    opacity: 0;
}

.employe-form-container.active {
    max-height: 500px; /* ou une hauteur suffisante pour contenir le formulaire */
    opacity: 1;
}


.historique-emploi-item {
    background-color: #f0f0f0; /* Couleur de fond */
    border-radius: 10px; /* Rayon du bord arrondi */
    padding: 10px; /* Espacement intérieur */
    margin-top: 10px; /* Marge entre les éléments */
    display: flex; /* Affichage en ligne */
    align-items: center; /* Alignement vertical des éléments */
    justify-content: space-between; /* Répartition de l'espace */
}

.label-container {
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin: 0px 10;
}

.label-title {
    font-weight: bold;
}

.multiline-input{
    width: 100%;
    border-radius: 10px;
    padding: 10px;
    box-sizing: border-box;
    resize: none;
    min-height: 100px;
}

.form-column{
   display: flex;
   flex-direction: column;
   gap: 10px;
   width: 100%;
}


.login-form {
    display: flex;
    align-items: center;
    width: 300px;
    flex-direction: column;
    border: 1px solid #ddd;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background: #fff;
    gap: 20px;
}


.success-icon {
    color: green;
    font-size: 18px;
    align-self: start;
}



.text-area-open {
    resize: none;
    min-width: 500px;
    max-width: 600px;
    width: 100%;
    min-height: 100px;
    overflow: auto;
    border-radius: 10px;
    padding: 10px;
    box-sizing: border-box;
}

.progress-bar {
    background-color: #f0f0f0;
    border-radius: 10px;
    width: 100%;
    height: 10px;
    margin-bottom: 20px;

  }
  
  .progress {
    background-color: #00667A;
    height: 100%;
    border-radius: 10px;
    transition: width 1s ease;
  }
  

/* PERF FORM  */

.perf-form-body {
    display: flex;
    align-items: flex-start;
    width: 100%;
    flex-direction: column;
    gap: 15px;

  }

  .perf-form-element{
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 5px;
    width: 100%;
  }

  .perf-form-btn{
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 5px;
    width: 100%;
  }

  .perf-form-element-label {
    font-size: small;
    font-weight: bold;
  }

  .perf-form-input {
    width: 100%;
    border-radius: 10px;
    border: 1px solid #ddd; /* Bordure subtile */
    padding: 8px 10px;
    transition: background-color 0.3s;
    box-sizing: border-box;
    align-items: flex-start;
  }

  .perf-form-input:hover {
    background-color: #f5f9fa;
}

.perf-form-section{
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
    border-radius: 10px;
    gap: 15px;
    padding: 10px;
}

.perf-form-separator{
    background-color: #00000020;
    width: 100%;
    height: 1px;
}

.modal-form {
    position: fixed;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 20px;
    z-index: 1000;
  }

.overlay.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 1000;
  }