.filters-container-center{
    display: flex;
    flex-direction: row;
    gap: 5px;

}



/* Conteneur du champ de recherche */
.search-input {
    width: 100%;
    padding: 10px 15px;
    font-size: 12px;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
    transition: border-color 0.3s ease-in-out;
    max-width: 250px;
  }
  
  .search-input:focus {
    border-color: #00667A; /* Couleur bleue moderne pour focus */
    box-shadow: 0 0 5px rgba(0, 102, 122, 0.5);
  }
  
  /* Conteneur des résultats de recherche */
  .search-results {
    margin-top: 10px; 
    margin-top: 5px;
    max-height: 250px; /* Pour limiter la hauteur des résultats */
    overflow-y: auto;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    position: absolute; /* Nécessaire pour overlay */
    width: 100%;
  }
  
  /* Élément de résultat individuel */
  .search-item {
    padding: 10px 15px;
    font-size: 12px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
  }
  
  .search-item:hover {
    background-color: #eaebed; /* Légère couleur grise sur hover */
  }
  
  /* Pas de résultats trouvés */
  .search-results::after {
    content: "No results found.";
    display: block;
    color: #888;
    font-size: 12px;
    padding: 10px 15px;
    text-align: center;
    font-style: italic;
  }
  
  /* Animation lors de l'affichage des résultats */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  .search-container {
    position: relative;
  }
  
  .search-results {
    animation: fadeIn 0.3s ease-in-out;
  }